import {
  Badge,
  Box,
  Button,
  Code,
  Flex,
  Td,
  Text,
  Tr,
  useClipboard,
  useDisclosure,
} from "@chakra-ui/react";
import { Tooltip } from "@chakra-ui/react";
import ChainInfo from "components/Chains/ChainInfo";
import DecodeValuesModal from "components/Modals/DecodeValuesModal";
import { useState } from "react";
import { BiSolidUpArrowSquare } from "react-icons/bi";
import { IoCheckmarkDoneSharp, IoCopyOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { getTimeFromNow } from "utils";
import { getChainIDToName } from "utils/constants";
import { chainIDToName } from "utils/constants";
import { getChainLogo } from "utils/constants";

const RequestRow = ({ row, lastItem }) => {
  const {
    id,
    requestId,
    numCommits,
    indexedAt,
    requestFinalizedEpoch,
    requestFirstCommittedEpoch,
    resultDestinationChainDomain,
    resultRequestingChainDomain,
    resultTarget,
    resultValues,
    status,
    epochConsensusReached,
  } = row;

  const [isCopied, setIsCopied] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onCopy } = useClipboard(resultValues);

  const handleCopy = () => {
    onCopy();
    setIsCopied(true);
    // Reset the copied state after a delay
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <>
    <Tr>
      <Td
        minWidth={{ sm: "250px" }}
        ps="0px"
        border={lastItem ? "none" : null}
        borderBottomColor="#56577A"
      >
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {requestId ? <Link to={`/requests/${id}`}>{requestId}</Link> : "-"}
        </Text>
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Badge fontSize="sm" p="3px 10px" borderRadius="8px" fontWeight="bold">
          {status ?? "-"}
        </Badge>
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <ChainInfo chainId={resultRequestingChainDomain} />
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <ChainInfo chainId={resultDestinationChainDomain} />
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Flex alignItems="center">
          {resultValues ? (
            <>
              <Box className="cursor-pointer" mr="2">
                <BiSolidUpArrowSquare onClick={onOpen} />
              </Box>
              <Code className="code-ellipsis ml-2">{resultValues}</Code>
              <Button
                onClick={handleCopy}
                padding={0}
                colorScheme="gray"
                ml="4px"
              >
                {isCopied ? <IoCheckmarkDoneSharp /> : <IoCopyOutline />}
              </Button>
            </>
          ) : (
            "-"
          )}
        </Flex>
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {requestFirstCommittedEpoch ?? "-"}
        </Text>
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {requestFinalizedEpoch ?? "-"}
        </Text>
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {numCommits ?? "-"}
        </Text>
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {indexedAt ? getTimeFromNow(indexedAt) : "-"}
        </Text>
      </Td>
    </Tr>
    {resultValues && (
      <DecodeValuesModal
        isOpen={isOpen}
        onClose={onClose}
        resultValues={resultValues}
      />
    )}
    </>
  )
}

export default RequestRow;
