import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  VStack,
  Button,
  Code,
} from "@chakra-ui/react";
import { useState } from "react";
import { ethers } from "ethers";

const DecodeValuesModal = ({ isOpen, onClose, resultValues }) => {
  const [types, setTypes] = useState("");
  const [decodedValue, setDecodedValue] = useState("");

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="gray.800">
        <ModalHeader color="white">Decode Result Values</ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody pb={6}>
          <VStack spacing={4}>
            <Input
              placeholder="Enter types (e.g. uint256,address)"
              value={types}
              onChange={(e) => setTypes(e.target.value)}
              color="white"
            />
            <Button
              onClick={() => {
                try {
                  const typeArray = types.split(",").map((t) => t.trim());
                  const abiCoder = new ethers.utils.AbiCoder();
                  const decoded = abiCoder.decode(typeArray, resultValues);
                  setDecodedValue(JSON.stringify(decoded, null, 2));
                } catch (error) {
                  setDecodedValue(`Error decoding: ${error.message}`);
                }
              }}
              colorScheme="blue"
            >
              Decode
            </Button>
            {decodedValue && (
              <Code p={4} borderRadius="md" width="100%" whiteSpace="pre-wrap">
                {decodedValue}
              </Code>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DecodeValuesModal;
