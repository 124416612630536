import {
  Box,
  Button,
  Code,
  Flex,
  Td,
  Text,
  Tr,
  useClipboard,
} from "@chakra-ui/react";
import Address from "components/Address/Address";
import { useState } from "react";
import { BiSolidUpArrowSquare } from "react-icons/bi";
import { IoCheckmarkDoneSharp, IoCopyOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { getTimeFromNow } from "utils";

const BlocksRow = ({ row, lastItem }) => {
  const {
    blockWinner,
    epoch,
    dynasty,
    messageData,
    sender,
    transactionHash,
    timestamp,
    signerAddress,
    numRequests,
  } = row;
  const [isCopied, setIsCopied] = useState(false);
  const { onCopy } = useClipboard(messageData);

  const handleCopy = () => {
    onCopy();
    setIsCopied(true);
    // Reset the copied state after a delay
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <Tr>
      <Td
        minWidth={{ sm: "250px" }}
        ps="0px"
        border={lastItem ? "none" : null}
        borderBottomColor="#56577A"
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            {sender ? <Address address={sender} /> : "-"}
          </Flex>
        </Flex>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {timestamp ? getTimeFromNow(timestamp) : "-"}
        </Text>
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Flex alignItems="center">
          {messageData ? (
            <>
              <Box className="cursor-pointer" mr="2">
                <BiSolidUpArrowSquare onClick={() => {}} />
              </Box>
              <Code className="code-ellipsis ml-2">{messageData}</Code>
              <Button
                onClick={handleCopy}
                padding={0}
                colorScheme="gray"
                ml="4px"
              >
                {isCopied ? <IoCheckmarkDoneSharp /> : <IoCopyOutline />}
              </Button>
            </>
          ) : (
            "-"
          )}
        </Flex>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {numRequests ?? "-"}
          {/* {blockWinner ?? "-"} */}
        </Text>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text
          fontSize="sm"
          color="#fff"
          fontWeight="bold"
          pb=".5rem"
          className="custom-ellipsis"
        >
          {transactionHash ? (
            <Link to={`/transactions/${transactionHash}`}>
              {transactionHash}
            </Link>
          ) : (
            "-"
          )}
        </Text>
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {epoch ? <Link to={`/blocks/${epoch}`}>{epoch}</Link> : "-"}
        </Text>
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {dynasty || "-"}
        </Text>
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {blockWinner ?? "-"}
        </Text>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            {signerAddress ? <Address address={signerAddress} /> : "-"}
          </Flex>
        </Flex>
      </Td>
    </Tr>
  );
};

export default BlocksRow;
