import React, { useState, useEffect, useMemo } from "react";
import { Box, Flex, Select, Text } from "@chakra-ui/react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useLazyQuery } from "@apollo/client";
import { FETCH_VALIDATOR_STATS } from "utils/queries";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

const StatsChart = ({ validatorId }) => {
  const [chartData, setChartData] = useState([]);
  const [displayMetric, setDisplayMetric] = useState("commits");
  const [
    getValidatorStats,
    { data: validatorStatsData, loading, error },
  ] = useLazyQuery(FETCH_VALIDATOR_STATS, {
    variables: { validatorId: validatorId?.toString() },
  });

  useEffect(() => {
    let mounted = true;

    if (validatorId) {
      getValidatorStats();
    }

    return () => {
      mounted = false;
    };
  }, [validatorId]);

  const transformedChartData = useMemo(() => {
    if (!validatorStatsData?.validatorStats) return [];

    // Sort by dynasty to ensure proper chronological order
    return [...validatorStatsData.validatorStats].sort(
      (a, b) => parseInt(a.dynasty) - parseInt(b.dynasty)
    );
  }, [validatorStatsData]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setChartData(transformedChartData);
    }
    return () => {
      mounted = false;
    };
  }, [transformedChartData]);

  if (error) {
    return (
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardBody>
          <Text color="red.400" textAlign="center">
            Error loading validator statistics. Please try again later.
          </Text>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }} mb={10}>
      <CardHeader p="6px 0px 22px 0px">
        <Flex
          justify="space-between"
          align="center"
          w="100%"
          px={{ base: 3, md: 6 }}
          flexDir={{ base: "column", sm: "row" }}
          gap={{ base: 3, sm: 0 }}
        >
          <Text fontSize="lg" color="#fff" fontWeight="bold">
            Validator Statistics
          </Text>
        </Flex>
      </CardHeader>
      <CardBody>
        <Box
          p={{ base: 2, md: 4 }}
          minH="450px"
          width="100%"
          sx={{
            ".recharts-wrapper": {
              minWidth: "300px",
            },
          }}
        >
          {loading ? (
            <Text color="gray.400" textAlign="center">
              Loading statistics...
            </Text>
          ) : chartData.length === 0 ? (
            <Text color="gray.400" textAlign="center">
              No statistics available for this validator.
            </Text>
          ) : (
            <ResponsiveContainer width="100%" height={400} minWidth={300}>
              <LineChart
                data={chartData}
                margin={{ top: 5, right: 20, left: -10, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#56577A" />
                <XAxis
                  dataKey="dynasty"
                  stroke="#fff"
                  tick={{ fill: "#fff" }}
                  angle={-45}
                  textAnchor="end"
                  height={60}
                  fontSize={12}
                  interval={0}
                  tickMargin={20}
                />
                <YAxis
                  stroke="#fff"
                  tick={{ fill: "#fff", fontSize: 12 }}
                  width={45}
                  tickFormatter={(value) => value.toLocaleString()}
                  tickMargin={8}
                  minTickGap={5}
                />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "#0f1535",
                    border: "1px solid #56577A",
                    color: "#fff",
                    fontSize: "12px",
                    padding: "8px",
                  }}
                  wrapperStyle={{
                    outline: "none",
                  }}
                  formatter={(value, name) => [
                    value,
                    name === "Commits" ? "Total Commits" : "Total Reveals",
                  ]}
                  labelFormatter={(dynasty) => `Dynasty ${dynasty}`}
                />
                <Legend
                  wrapperStyle={{
                    paddingTop: "20px",
                  }}
                />
                <Line
                  type="monotone"
                  dataKey="numCommits"
                  stroke="#4FD1C5"
                  name="Commits"
                  dot={{ r: 4 }}
                  activeDot={{ r: 6, stroke: "#fff" }}
                />
                <Line
                  type="monotone"
                  dataKey="numReveals"
                  stroke="#7551FF"
                  name="Reveals"
                  dot={{ r: 4 }}
                  activeDot={{ r: 6, stroke: "#fff" }}
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </Box>
      </CardBody>
    </Card>
  );
};

export default StatsChart;
