import { useEffect } from "react";
import { MultiSelect } from "chakra-multiselect";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Table,
  TableCaption,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

// Custom components
import { useLazyQuery } from "@apollo/client";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import RequestRow from "components/Tables/RequestRow";
import SkeletonTable from "components/Tables/SkeletonTable";
import { useState } from "react";
import { FETCH_REQUESTS } from "utils/queries";
import SearchBar from "components/SearchBar/SearchBar";
import { requestStatusOptions } from "utils/constants";
import { FETCH_REQUESTS_BY_STATUS } from "utils/queries";
import { FETCH_REQUEST } from "utils/queries";
import { FETCH_REQUESTS_BY_STATUS_AND_REQUEST_ID } from "utils/queries";
import { FETCH_REQUESTS_BY_REQUESTING_CHAIN } from "utils/queries";
import { FETCH_REQUESTS_BY_DESTINATION_CHAIN } from "utils/queries";
import { FETCH_REQUESTS_BY_STATUS_AND_REQUESTING_CHAIN } from "utils/queries";
import { FETCH_REQUESTS_BY_STATUS_AND_DESTINATION_CHAIN } from "utils/queries";

const SEARCH_TYPES = {
  REQUEST_ID: "requestId",
  REQUESTING_CHAIN: "requestingChain",
  DESTINATION_CHAIN: "destinationChain",
};

function Requests() {
  const NUM_REQUESTS = 50;
  const [searchType, setSearchType] = useState(SEARCH_TYPES.REQUEST_ID);
  const [searchValue, setSearchValue] = useState("");
  const [requests, setRequests] = useState([]);
  const [option, setOption] = useState([]); // Filter by events
  const [skip, setSkip] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [getAllRequests] = useLazyQuery(FETCH_REQUESTS);
  const [getAllRequestByStatus] = useLazyQuery(FETCH_REQUESTS_BY_STATUS);
  const [fetchRequestByRequestId] = useLazyQuery(FETCH_REQUEST);
  const [fetchRequestByStatusAndRequestId] = useLazyQuery(
    FETCH_REQUESTS_BY_STATUS_AND_REQUEST_ID
  );
  const [fetchRequestsByRequestingChain] = useLazyQuery(
    FETCH_REQUESTS_BY_REQUESTING_CHAIN
  );
  const [fetchRequestsByDestinationChain] = useLazyQuery(
    FETCH_REQUESTS_BY_DESTINATION_CHAIN
  );
  const [fetchRequestsByStatusAndRequestingChain] = useLazyQuery(
    FETCH_REQUESTS_BY_STATUS_AND_REQUESTING_CHAIN
  );
  const [fetchRequestsByStatusAndDestinationChain] = useLazyQuery(
    FETCH_REQUESTS_BY_STATUS_AND_DESTINATION_CHAIN
  );

  useEffect(() => {
    console.log({ searchType, searchValue });
  }, [searchType, searchValue]);

  const updateRequestsState = (data, currentSkip) => {
    if (data.requests.length < NUM_REQUESTS) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
    if (currentSkip === 0) {
      setRequests(data.requests);
    } else {
      setRequests((prevRequests) => [...prevRequests, ...data.requests]);
    }
  };

  const fetchAllRequests = async (currentSkip) => {
    console.log("Fetching all requests");
    console.log({ option, searchValue, searchType, currentSkip });
    setIsLoading(true);
    const variables = {
      orderBy: `indexedAt`,
      orderDirection: `desc`,
      first: NUM_REQUESTS,
      skip: currentSkip,
    };

    try {
      let data;

      if (option.length > 0) {
        console.log({ searchType, searchValue });
        if (searchValue) {
          if (searchType === SEARCH_TYPES.REQUEST_ID) {
            data = await fetchRequestByStatusAndRequestId({
              variables: {
                ...variables,
                status: option,
                requestId: searchValue,
              },
            });
          } else if (searchType === SEARCH_TYPES.REQUESTING_CHAIN) {
            data = await fetchRequestsByStatusAndRequestingChain({
              variables: {
                ...variables,
                requestingChainDomain: searchValue,
                status: option,
              },
            });
          } else if (searchType === SEARCH_TYPES.DESTINATION_CHAIN) {
            data = await fetchRequestsByStatusAndDestinationChain({
              variables: {
                ...variables,
                destinationChainDomain: searchValue,
                status: option,
              },
            });
          }
        } else {
          data = await getAllRequestByStatus({
            variables: {
              ...variables,
              status: option,
            },
          });
        }
      } else if (searchValue) {
        if (searchType === SEARCH_TYPES.REQUEST_ID) {
          data = await fetchRequestByRequestId({
            variables: {
              ...variables,
              requestId: searchValue,
            },
          });
        } else if (searchType === SEARCH_TYPES.REQUESTING_CHAIN) {
          data = await fetchRequestsByRequestingChain({
            variables: {
              ...variables,
              requestingChainDomain: searchValue,
            },
          });
        } else if (searchType === SEARCH_TYPES.DESTINATION_CHAIN) {
          data = await fetchRequestsByDestinationChain({
            variables: {
              ...variables,
              destinationChainDomain: searchValue,
            },
          });
        }
      } else {
        data = await getAllRequests({
          variables,
        });
      }

      updateRequestsState(data.data, currentSkip);
    } catch (error) {
      console.log("Error fetching requests", error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMore = () => {
    const newSkip = skip + NUM_REQUESTS;
    setSkip(newSkip); // Update the skip state
    fetchAllRequests(newSkip); // Fetch the next batch
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setSkip(0);
      fetchAllRequests(0);
    }, 500);

    return () => clearTimeout(debounceTimer);
  }, [option, searchValue, searchType]);

  const resetData = () => {
    setSkip(0);
    getAllRequests({
      variables: {
        orderBy: "indexedAt",
        orderDirection: "desc",
        first: NUM_REQUESTS,
        skip: 0,
      },
    }).then((response) => {
      const { data } = response;
      setRequests(data?.requests);
    });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card
        // overflowX={{ sm: "scroll", xl: "hidden" }}
        pb="0px"
        className="scroll-content"
      >
        <CardHeader p="6px 0px 22px 0px" w="95%" flexDirection="column">
          <Text fontSize="lg" color="#fff" fontWeight="bold">
            Requests
          </Text>
          <Flex
            justifyContent="space-between"
            alignItems="flex-start"
            w="100%"
            mt="30px"
            flexDir={{ base: "column", md: "row" }}
            gap={{ md: 0 }}
          >
            <Box
              display="flex"
              alignItems="center"
              w={{ base: "100%" }}
              flexDir={{ base: "column", sm: "row" }}
            >
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  size="md"
                  w={{ base: "100%", sm: "auto" }}
                  minW="200px"
                  bg="gray.700"
                  color="white"
                  borderColor="whiteAlpha.200"
                  _hover={{ bg: "whiteAlpha.200" }}
                  _active={{ bg: "whiteAlpha.300" }}
                >
                  {searchType === SEARCH_TYPES.REQUEST_ID
                    ? "Request ID"
                    : searchType === SEARCH_TYPES.REQUESTING_CHAIN
                    ? "Requesting Chain"
                    : "Destination Chain"}
                </MenuButton>
                <MenuList bg="gray.800" borderColor="whiteAlpha.200">
                  <MenuItem
                    onClick={() => setSearchType(SEARCH_TYPES.REQUEST_ID)}
                    _hover={{ bg: "whiteAlpha.200" }}
                    color="white"
                  >
                    Request ID
                  </MenuItem>
                  <MenuItem
                    onClick={() => setSearchType(SEARCH_TYPES.REQUESTING_CHAIN)}
                    _hover={{ bg: "whiteAlpha.200" }}
                    color="white"
                  >
                    Requesting Chain
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      setSearchType(SEARCH_TYPES.DESTINATION_CHAIN)
                    }
                    _hover={{ bg: "whiteAlpha.200" }}
                    color="white"
                  >
                    Destination Chain
                  </MenuItem>
                </MenuList>
              </Menu>
              <Box flex={1} minW={{ base: "100%" }}>
                <SearchBar
                  setSearchBarData={() => {}}
                  resetData={resetData}
                  parent="requests"
                  selectedActions={option}
                  setFilterAddress={setSearchValue}
                  searchPlaceholder={
                    searchType === SEARCH_TYPES.REQUEST_ID
                      ? "Search by request ID"
                      : searchType === SEARCH_TYPES.REQUESTING_CHAIN
                      ? "Search by requesting chain"
                      : "Search by destination chain"
                  }
                />
              </Box>
            </Box>

            <Box
              className="custom-multi-select"
              w={{ base: "100%", md: "auto" }}
              minW="200px"
              sx={{
                ".chakra-multiselect__menu": {
                  bg: "gray.800",
                  borderColor: "whiteAlpha.200",
                  color: "white",
                },
                ".chakra-multiselect__option": {
                  _hover: { bg: "whiteAlpha.200" },
                  "&[data-selected]": {
                    bg: "blue.500",
                  },
                  "&[data-focused]": {
                    bg: "whiteAlpha.200",
                  },
                },
              }}
            >
              <MultiSelect
                options={requestStatusOptions}
                value={option}
                onChange={setOption}
                placeholder="Filter by Event"
                size="md"
                multiple
              />
            </Box>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color="#fff">
            <TableCaption cursor="pointer" _hover={{ color: "#fff" }}>
              <Button
                onClick={loadMore}
                isLoading={isLoading}
                isDisabled={!hasMore}
              >
                Load More
              </Button>
            </TableCaption>
            <Thead>
              <Tr my=".8rem" ps="0px" color="gray.400">
                <Th
                  ps="0px"
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Request ID
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Status
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Requesting Chain
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Desitnation Chain
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Value
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  First Committed Epoch
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Finalized Epoch
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Number of Commits
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Indexed At
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading && requests.length === 0 ? (
                <SkeletonTable rowCount={10} />
              ) : requests.length === 0 ? (
                <RequestRow row={requests} index={0} lastItem={true} />
              ) : (
                requests.length > 0 &&
                requests.map((row, index, arr) => {
                  return (
                    <RequestRow
                      row={row}
                      key={index}
                      lastItem={index === arr.length - 1 ? true : false}
                    />
                  );
                })
              )}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Requests;
